import Vue from 'vue'
import VueRouter from 'vue-router'
import routers from './router/router'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import VueVirtualScroller from "vue-virtual-scroller";
import { getUrlCode } from '@/utils/compress.js'

Vue.use(VueVirtualScroller);
/*引入 vant 组件开始*/
import { NavBar } from 'vant';
Vue.use(NavBar);

import { Icon } from 'vant';
Vue.use(Icon);

import { Search } from 'vant';
Vue.use(Search);

import { List } from 'vant';
Vue.use(List);

import { Cell, CellGroup } from 'vant';
Vue.use(Cell);
Vue.use(CellGroup);

import { Field } from 'vant';
Vue.use(Field);

import { ActionSheet } from 'vant';
Vue.use(ActionSheet);

import { RadioGroup, Radio } from 'vant';
Vue.use(Radio);
Vue.use(RadioGroup);

import { Divider } from 'vant';
Vue.use(Divider);

import { Stepper } from 'vant';
Vue.use(Stepper);

import { Badge } from 'vant';
Vue.use(Badge);

import { DatetimePicker } from 'vant';
Vue.use(DatetimePicker);

import { Toast } from 'vant';
Vue.use(Toast);

import { Popup } from 'vant';
Vue.use(Popup);

import { Dialog } from 'vant';
Vue.use(Dialog);

import { Switch } from 'vant';
Vue.use(Switch);

import { Uploader } from 'vant';
Vue.use(Uploader);

import { Overlay } from 'vant';
Vue.use(Overlay);

import { Checkbox, CheckboxGroup } from 'vant';
Vue.use(Checkbox);
Vue.use(CheckboxGroup);

import { Image as VanImage } from 'vant';
Vue.use(VanImage);

import { Button } from 'vant';
Vue.use(Button);

import { Step, Steps } from 'vant';
Vue.use(Step);
Vue.use(Steps);

import { DropdownMenu, DropdownItem } from 'vant';
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
/*引入 vant 组件结束*/

Vue.use(VueRouter)
Vue.config.productionTip = false
const router = new VueRouter({
  mode: 'history',
  base: "/",//部署的子路径
  routes: routers
})

router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	// 判断是不是微信环境
	if (to.name !== 'Home' && !sessionStorage.getItem("token")){
		let query = getUrlCode();
		// 记录打开的是那个页面，登录成功后再跳回去
		sessionStorage.setItem("redirect", location.pathname.substr(1))
		sessionStorage.setItem("search", JSON.stringify(query))
		next({ name: 'Home' })
	} else {
		next()
	}
})

new Vue({
  el: '#app',
  router,
  render: h => h(App),
}).$mount('#app')
